<div class="custom-modal-wrapper">
  <div class="close-icon-wrapper">
    <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
  </div>
  <div class="custom-modal">
    <h3 class="modal-title">
      {{ 'JOBS.USE_PREDEFINED_SKILL_QUESTION' | translate }}
    </h3>
    <div class="modal-body">

      <!-- FILTERS -->
      <form [formGroup]="filterForm">
        <!-- CATEGORY -->
        <div class="col-md-12 col-lg-6 display-inline">
          <div class="form-group">
            <h4>{{ 'JOBS.CATEGORY_LABEL' | translate }}</h4>
            <div class="optional-select-wrapper">
              <select formControlName="category"
                      class="form-control"
                      (change)="setFormValues('category')">
                <option selected
                        [ngValue]="null"
                        class="d-none"></option>
                <option disabled
                        *ngIf="categories?.length === 0"
                        [value]="">{{ 'SHARED.NO_DATA' | translate }}</option>
                <option *ngFor="let category of categories"
                        [ngValue]="category.id">{{ category.name[translationLanguage] }}</option>
              </select>
            </div>
          </div>
        </div>

        <!-- SUBCATEGORY -->
        <div class="col-md-12 col-lg-6 display-inline">
          <div class="form-group">
            <h4>{{ 'JOBS.SUBCATEGORY_LABEL' | translate }}</h4>
            <div class="optional-select-wrapper">
              <select formControlName="subcategory"
                      class="form-control subcategory-select"
                      (change)="selectedFilter('subcategory')">
                <option selected
                        [ngValue]="null"
                        class="d-none"></option>
                <option disabled
                        *ngIf="subcategories?.length === 0"
                        [value]="">{{ 'SHARED.NO_DATA' | translate }}</option>
                <option *ngFor="let subcategory of subcategories"
                        [ngValue]="subcategory.id">{{ subcategory.name[translationLanguage] }}</option>
              </select>
              <span *ngIf="subcategory.value" class="remove-wrapper" (click)="resetControl('subcategory')">
                ×
              </span>
            </div>
          </div>
        </div>
      </form>

      <!-- LIST OF QUESTIONS -->
      <app-question-list class="question-list"
                        [questionsList]="questionsList"
                         [selectedQuestions]="selectedQuestions"
                         (callScroll)="onScroll()"
                         [hideShowIcon]="false"
                         [selectQuestionWithId]="false"
                         [selectQuestionWithoutId]="true">
      </app-question-list>
    </div>

    <div class="button-wrapper">
      <button (click)="closeModal()"
              class="btn btn-outline-primary">
        {{ "BUTTONS.CANCEL" | translate }}
      </button>
      <button class="btn btn-primary border-white"
              (click)="useQuestions()">
        {{ "BUTTONS.APPLY" | translate }}
      </button>
    </div>

  </div>
</div>
