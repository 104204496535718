import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Question } from 'src/app/model/job.interface';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { JobStoreService } from 'src/app/services/job-store.service';
import { JobsService } from 'src/app/services/jobs.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ModalService } from 'src/app/services/modal.service';
import { QuizService } from 'src/app/services/quiz.service';
import { AbstractSkillsLibrary } from 'src/app/model/abstract-skills-library.model';
import { catchError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-ai-skills-questions',
  templateUrl: './ai-skills-questions.component.html',
  styleUrls: ['./ai-skills-questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiSkillsQuestionsComponent extends AbstractSkillsLibrary implements OnInit {
  questionsList: Question[] = [];
  keyWord = '';
  keyWords: string[] = [];
  counterTime = 120;

  constructor(
    modalService: ModalService,
    cdr: ChangeDetectorRef,
    toastr: ToastrService,
    translateService: TranslateService,
    quizService: QuizService,
    errorHandlingService: ErrorHandlingService,
    private loaderService: LoaderService,
    private jobStore: JobStoreService,
    private jobService: JobsService,
  ) {
    super(modalService, cdr, toastr, translateService, quizService, errorHandlingService);
  }

  get generatedAiQuestions(): boolean {
    return this.jobStore.getItem('ai') || (this.data.isExpressInterest && this.questionsList.length);
  }

  ngOnInit(): void {
    if (this.generatedAiQuestions) {
      this.questionsList = this.jobStore.getItem('ai');
    }
  }

  getData(): void {

    this.loaderService.showCounter(this.counterTime);

    const jobDescription = this.data.jobDescription;

    let discription = jobDescription.replace(/\n/g, '\\n').replace(/<[^>]*>/g, '');

    if (!this.data.isExpressInterest) {
      discription = discription.replace(/<[^>]*>/g, '');
    }

    const keyWords = this.keyWords.join(', ');

    const payload = {
      jobOrJobdraftTitle: this.data.jobTitle,
      jobOrJobdraftDescription: discription,
      keyWords: keyWords,
      language: this.jobStore.job.jobInfo?.language || this.data.expressInterestLanguage
    };

    this.jobService.getAISkillsQuestions(payload).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        this.loaderService.hideCounter();
        return this.errorHandlingService.handleBackendError(errorResponse);
      }
      )
    ).subscribe((aiQuestions: { questions: Question[] }) => {
        this.questionsList = aiQuestions.questions;
        if (!this.data.isExpressInterest) {
          this.jobStore.setItem('ai', this.questionsList);
        }
        this.cdr.detectChanges();

        this.loaderService.hideCounter();
      });
  }

  setFormValues(): void { }

  loadMoreQuestions(): void { }

  onEnter(): void {
    if (!this.keyWord) {
      this.toastr.error(
        this.translateService.instant('JOBS.INVALID_SECTION.INFO')
      );
      return;
    }

    if (this.keyWords.length === 12) {
      this.toastr.error(
        this.translateService.instant('JOBS.MAX_NUMBER_OF_KEY_WORDS')
      );
      return;
    }

    this.keyWords.push(this.keyWord);
    this.keyWord = '';
  }

  removeKeyWord(i: number): void {
    this.keyWords.splice(i, 1);
  }
}
