import { AbstractModal } from '../modules/modal/abstract-modal';
import { CandidateCardComponent } from '../modules/candidate/candidate-card/candidate-card.component';
import { ConfirmationModalComponent } from '../modules/modal/confirmation-modal/confirmation-modal.component';
import {
  AgreementFormModalData,
  AgreementModalData,
  ArchiveJobModalData,
  CancelInterviewModalData,
  CandidateCardModalData,
  CaptureVideoPosterModalData,
  ConfirmationModalData,
  DeleteUserModalData,
  HiddenSkillsQuestionListModalData,
  LibraryModalData,
  PerformActionModalData,
  PreviewDraftModalData,
  RequisitionListModalData,
  RequisitionModalData,
  SkillsLibraryModalData, UniversalModalData
} from '../model/modal.interface';
import { PerformActionComponent } from '../modules/perform-action/perform-action/perform-action.component';
import { LibraryComponent } from '../modules/job/job-components/library/library.component';
import { SkillsLibraryComponent } from '../modules/job/job-quiz-modules/business-quiz/skills-library/skills-library.component';
import { DeleteUserModalComponent } from '../modules/modal/delete-user-modal/delete-user-modal.component';
import { UniversalModalComponent } from '../modules/modal/universal-modal/universal-modal.component';
import { CancelInterviewComponent } from '../modules/decline-interview/cancel-interview/cancel-interview.component';
import { CaptureVideoPosterComponent } from '../modules/job/job-components/capture-video-poster/capture-video-poster.component';
import { RequisitionComponent } from '../modules/requisition/requisition/requisition.component';
import { RequisitionsListModalComponent } from '../modules/requisition/requisitions-list-modal/requisitions-list-modal.component';
import { ArchiveJobModalComponent } from '../modules/modal/archive-job-modal/archive-job-modal.component';
import { GlobalLibraryComponent } from '../modules/job/job-quiz-modules/business-quiz/global-library/global-library.component';
import { HiddenSkillsQuestionModalComponent } from '../modules/job/job-quiz-modules/business-quiz/hidden-skills-question-modal/hidden-skills-question-modal.component';
import { AiSkillsQuestionsComponent } from '../modules/job/job-quiz-modules/business-quiz/ai-skills-questions/ai-skills-questions.component';
import { JobPreviewComponent } from '../modules/job/job-create/job-preview/job-preview.component';
import { AgreementFormComponent } from '../modules/agreement/agreement-form/agreement-form.component';
import { AgreementComponent } from '../modules/agreement/agreement/agreement.component';

export class Modal {

  type: typeof AbstractModal;
  data: any;

  constructor(type: typeof AbstractModal, data?: any) {
    this.type = type;
    this.data = data;
  }
}

export class ConfirmationModal extends Modal {

  data: ConfirmationModalData;

  constructor(data: ConfirmationModalData) {
    super(ConfirmationModalComponent, data);
  }
}

export class CandidateCardModal extends Modal {

  data: CandidateCardModalData;

  constructor(data: CandidateCardModalData) {
    super(CandidateCardComponent, data);
  }
}

export class PreviewDraftModal extends Modal {

  data: PreviewDraftModalData;

  constructor(data: PreviewDraftModalData) {
    super(JobPreviewComponent, data);
  }
}

export class LibraryModal extends Modal {

  data: LibraryModalData;

  constructor(data: LibraryModalData) {
    super(LibraryComponent, data);
  }
}

export class CaptureVideoPosterModal extends Modal {
  data: CaptureVideoPosterModalData;
  constructor(data: CaptureVideoPosterModalData) {
    super(CaptureVideoPosterComponent, data);
  }
}

export class SkillsLibraryModal extends Modal {

  data: SkillsLibraryModalData;

  constructor(data: SkillsLibraryModalData) {
    super(SkillsLibraryComponent, data);
  }
}

export class GlobalSkillsLibraryModal extends Modal {

  data: SkillsLibraryModalData;

  constructor(data: SkillsLibraryModalData) {
    super(GlobalLibraryComponent, data);
  }
}

export class AiSkillsQuestionsModal extends Modal {

  data: SkillsLibraryModalData;

  constructor(data: SkillsLibraryModalData) {
    super(AiSkillsQuestionsComponent, data);
  }
}

export class PerformActionModal extends Modal {

  data: PerformActionModalData;

  constructor(data: PerformActionModalData) {
    super(PerformActionComponent, data);
  }
}

export class DeleteUserModal extends Modal {

  data: DeleteUserModalData;

  constructor(data: DeleteUserModalData) {
    super(DeleteUserModalComponent, data);
  }
}

export class UniversalModal extends Modal {

  data: UniversalModalData;

  constructor(data: UniversalModalData) {
    super(UniversalModalComponent, data);
  }
}

export class CancelInterviewModal extends Modal {

  data: CancelInterviewModalData;

  constructor(data: CancelInterviewModalData) {
    super(CancelInterviewComponent, data);
  }
}

export class RequisitionModal extends Modal {

  constructor(data: RequisitionModalData) {
    super(RequisitionComponent, data);
  }
}

export class RequisitionListModal extends Modal {

  data: RequisitionListModalData;

  constructor(data: RequisitionListModalData) {
    super(RequisitionsListModalComponent, data);
  }
}
export class ArchiveJobModal extends Modal {

  data: ArchiveJobModalData;

  constructor(data: ArchiveJobModalData) {
    super(ArchiveJobModalComponent, data);
  }
}
export class HiddenSkillsQuestionListModal extends Modal {

  data: HiddenSkillsQuestionListModalData;

  constructor(data: HiddenSkillsQuestionListModalData) {
    super(HiddenSkillsQuestionModalComponent, data);
  }
}

export class AgreementModal extends Modal {

  constructor(data: AgreementModalData) {
    super(AgreementComponent, data);
  }
}

export class AgreementFormModal extends Modal {

  constructor(data: AgreementFormModalData) {
    super(AgreementFormComponent, data);
  }
}
