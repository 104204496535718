import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { catchError } from 'rxjs';
import { Question, TypeOfSkillsQuestion } from 'src/app/model/job.interface';
import { HiddenSkillsQuestionListModalData } from 'src/app/model/modal.interface';
import { Pagination, PaginationData } from 'src/app/model/pagination.interface';
import { AbstractModal } from 'src/app/modules/modal/abstract-modal';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ModalService } from 'src/app/services/modal.service';
import { QuizService } from 'src/app/services/quiz.service';

@Component({
  selector: 'app-hidden-skills-question-modal',
  templateUrl: './hidden-skills-question-modal.component.html',
  styleUrls: ['./hidden-skills-question-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HiddenSkillsQuestionModalComponent extends AbstractModal implements OnInit {
  hiddenQuestionList: Question[];
  pagination: PaginationData = { page: 1, perPage: 10, total: null };
  selectQuestionWithId = false;
  selectQuestionWithoutId = false;

  @Output() callScroll: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    modalService: ModalService,
    private quizService: QuizService,
    private errorHandlingService: ErrorHandlingService,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {
    super(modalService);
  }

  get data(): HiddenSkillsQuestionListModalData {
    return this.modal.data;
  }

  ngOnInit(): void {
    this.hiddenQuestionList = this.data.hiddenQuestionList;
    this.pagination.total = this.data.total;
  }

  mapReceivedQuestions(item: {question: Question, id?: number}): Question {
    const question = item.question;
    if (question.maxNumberOfAnswersCandidateCanChoose && question.maxNumberOfAnswersCandidateCanChoose > 1) {
      question.questionType = TypeOfSkillsQuestion.multipleAnswersQuestion;
    } else if (question.answers.findIndex((answer) => answer.followUpQuestion) > -1) {
      question.questionType = TypeOfSkillsQuestion.questionWithFollowUpQuestions;
    } else {
      question.questionType = TypeOfSkillsQuestion.singleAnswerQuestion;
    }
    if (item.id) {
      question.id = item.id;
    }
    return question;
  }

  onScroll(): void {
    if (this.pagination.page * this.pagination.perPage >= this.pagination.total) {
      return;
    }

    this.pagination.page++;

    this.loadMoreQuestions();
  }

  loadMoreQuestions(): void {
    this.loaderService.show();
    this.quizService
      .getSkillsQuestions(this.data.filter, this.pagination.page, this.pagination.perPage, true)
      .pipe(catchError((error: HttpErrorResponse) => this.errorHandlingService.handleBackendError(error)))
      .subscribe((questions: Pagination<{ question: Question }>) => {
        this.hiddenQuestionList = this.hiddenQuestionList.concat(questions.data.map((item) => this.mapReceivedQuestions(item)));
        this.pagination.total = questions.total;
        this.loaderService.hide();
        this.cdr.detectChanges();
      });
  }

  onCloseModal(): void {
    this.closeModal();
    this.data.close();
  }

  unhideSkillsQuestion(): void {
    this.pagination.page = 1;
    this.hiddenQuestionList = [];
    this.loadMoreQuestions();
  }
}
